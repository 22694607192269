import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: JSON.parse(localStorage.getItem("currentUser") || null)
    },
    getters: {
        // Add the `getField` getter to the
        // `getters` of your Vuex store instance.
        getField,
    },
    mutations: {
        // Add the `updateField` mutation to the
        // `mutations` of your Vuex store instance.
        updateField,
    },
    actions: {},
    modules: {}
})
