<template>
  <div class="form-group">
    <label>
      <slot>label</slot>
    </label>
    <input @input="$emit('input', $event.target.value)" v-bind="props"/>
  </div>

</template>

<script>
export default {
  name: "DInput",
  props: ['value'],
  computed: {
    props() {
      return {...this.$attrs, ...this.$props};
    }
  },
}
</script>

<style scoped lang=scss>
.form-group {
  margin: 10px;
  position: relative;
  border-radius: 15px;
  font-weight: bold;
  color: var(--tekst-donker);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.form-group > label {
  //position: absolute;
  //top: -20px;
  //left: 5px;
  //background: var(--achtergrond);
  font-size: 20px;
  padding: 2px 15px;
  box-sizing: border-box;
  text-align: start;
}

.form-group > input {
  color: var(--tekst-donker);
  padding: 10px 10px;
  font-weight: bold;
  outline: none;
  width: 100%;
  font-size: 36px;
  border-radius: 15px;
  background: var(--licht-achtergrond);
  border: var(--tekst-donker) 3px solid;
  box-sizing: border-box;
}

</style>