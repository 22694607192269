<template>
  <div class="home" v-if="currentUser">
    <h1>DINGO v3</h1>

    <d-input class="code" minlength="4" maxlength="4" v-model="currentUser.code">{{ $t('inputs.roomCode') }}</d-input>
    <d-input class="name" maxlength="16" v-model="currentUser.name">{{ $t('inputs.user.name') }}</d-input>

    <d-canvas ref="canvas" class="canvas">{{ $t('inputs.user.avatar') }}</d-canvas>

    <d-button ref="button" @click="joinGame" :disabled="!canJoinGame" class="meedoen">{{ $t('buttons.join') }}</d-button>
  </div>
</template>

<script>
// @ is an alias to /src

import DButton from "@/components/DButton";
import DInput from "@/components/DInput";
import DCanvas from "@/components/DCanvas";

import {mapFields} from 'vuex-map-fields';

import {v4 as uuidv4} from "uuid"

export default {
  name: 'Home',
  data() {
    return {
    }
  },
  components: {
    DCanvas,
    DInput,
    DButton
  },
  computed: {
    ...mapFields(['currentUser']),
    canJoinGame() {
      return this.currentUser.code.length === 4 && this.currentUser.name.length >= 2;
    }
  },
  methods: {
    async joinGame(e) {
      e.preventDefault()
      this.currentUser.avatar = await this.$refs.canvas.toDataURL()
      localStorage.setItem("currentUser", JSON.stringify(this.currentUser))
      this.$router.push('/lobby')

    },
  },
  mounted() {
    if (!this.currentUser) {
      this.currentUser = {
        id: uuidv4(),
        name: "",
        code: "",
        avatar: null,

      }
    }
    if (this.currentUser && this.currentUser.avatar) {
      this.$refs.canvas.setData64(this.currentUser.avatar)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  h1 {
    font-family: Bungee, serif !important;
    font-size: 50px;
    margin-top: 0;
  }
}

.meedoen {
  width: 300px;
  margin: 20px 0;
}

.code, .name {
  width: 300px;
}

.code, .name, .canvas {
  margin: 10px 0,
}

</style>

<style lang="scss">

.code {
  input {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 10px;
    padding-left: 20px !important;
  }
}

.name {
  input {

    text-align: center;
  }
}
</style>
