import * as SignalR from '@microsoft/signalr';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const EventEmitter = require('events');
import {EventEmitter} from "events"

const defaultOptions = {
    log: false,
    id: "",
    roomCode: "",
    name: "",
    playerAvatar: ""
};

class SocketConnection extends EventEmitter {
    constructor(connection) {
        super();

        this.connection = connection;
        this.listened = {};
        this.socket = false;

        this.toSend = [];

        this.offline = false;
    }

    async _initialize(connection = '') {
        const con = connection || this.connection;

        try {
            console.log("con:", con)
            let authCon = `${con}?isHost=false&roomCode=${this.options.roomCode.toUpperCase()}&playerId=${this.options.id}&name=${this.options.name}`
            console.log(authCon)

            const socket = new SignalR.HubConnectionBuilder()
                .withUrl(authCon)
                // .withAutomaticReconnect()
                .build()

            socket.connection.onclose = async (error, test) => {
                if (this.options.log) console.log('Reconnecting...');

                console.warn("CONNECTION CLOSED!!", error)
                console.log(error.toString())

                // this.socket = false;
                /* eslint-disable no-underscore-dangle */
                // setTimeout(async () => {
                //     await this._initialize(con);
                //     this.emit('reconnect');
                // }, 5000)
            };

            await socket.start();

            this.socket = socket;
            this.emit('init');
        } catch (error) {
            if (this.options.log) console.log('Error, reconnecting...');
            console.table(error)
            setTimeout(() => {
                this._initialize(con);
            }, 1000);
        }
    }

    async start(options = {}) {
        this.options = Object.assign(defaultOptions, options);

        await this._initialize();
    }

    async stop() {
        this.socket.stop()
    }

    async authenticate(accessToken, options = {}) {
        this.connection = `${this.connection}?authorization=${accessToken}`;

        /* eslint-disable no-underscore-dangle */
        await this.start(options);
    }

    listen(method) {
        if (this.offline) return;

        if (this.listened[method]) return;
        this.listened[method] = (...data) => {
            if (this.options.log) console.log({type: 'receive', method, data});
            this.emit(method, data);
        }


        console.log(this.listened)

        if (this.socket) {
            console.log("connection already available")
            this.socket.on(method, this.listened[method]);
        } else {
            console.log("waiting for init")
            this.on('init', () => {
                this.socket.on(method, this.listened[method]);
            });
        }


    }

    unlisten(method) {
        if (this.offline) return;
        if (!this.listened[method]) return;
        delete this.listened[method]

        this.socket.off(method);
    }

    send(methodName, ...args) {
        if (this.options.log) console.log({type: 'send', methodName, args});
        if (this.offline) return;

        if (this.socket) {
            this.socket.send(methodName, ...args);
            return;
        }

        this.once('init', () => this.socket.send(methodName, ...args));
    }

    async invoke(methodName, ...args) {
        if (this.options.log) console.log({type: 'invoke', methodName, args});
        if (this.offline) return false;

        if (this.socket) {
            return this.socket.invoke(methodName, ...args);
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve =>
            this.once('init', () =>
                resolve(this.socket.invoke(methodName, ...args))));
    }

}

if (!SignalR) {
    throw new Error('[Vue-SignalR] Cannot locate signalr-client');
}

function install(Vue, connection) {
    if (!connection) {
        throw new Error('[Vue-SignalR] Cannot locate connection');
    }

    const Socket = new SocketConnection(connection);

    Vue.socket = Socket;

    Object.defineProperties(Vue.prototype, {

        $socket: {
            get() {
                return Socket;
            },
        },

    });

    Vue.mixin({

        created() {
            if (this.$options.sockets) {
                const methods = Object.getOwnPropertyNames(this.$options.sockets);

                methods.forEach((method) => {
                    Socket.listen(method);

                    Socket.on(method, data => {
                        return this.$options.sockets[method].call(this, data)
                    });
                });
            }

            if (this.$options.subscribe) {
                Socket.on('authenticated', () => {
                    this.$options.subscribe.forEach((channel) => {
                        Socket.invoke('join', channel);
                    });
                });
            }
        },
        beforeDestroy() {
            if (this.$options.sockets) {
                const methods = Object.getOwnPropertyNames(this.$options.sockets);

                methods.forEach((method) => {
                    Socket.unlisten(method);

                    Socket.removeAllListeners(method);
                });
            }

            if (this.$options.subscribe) {
                this.$options.subscribe.forEach((channel) => {
                    Socket.invoke('leave', channel);
                });
            }
        }

    });
}

export default install;