<template>
  <section class="DCanvas">
    <label>
      <slot/>
    </label>
    <section class="wrapper">
      <canvas ref="canvas"
              :width="resolution" :height="resolution">

      </canvas>
      <section class="buttons">
        <d-button @click="setColor('#464686')" :style="{'--color': '#464686'}"
                  :class="{active: currentColor === '#464686'}" class="button undo color">
          <div class="circle"></div>
        </d-button>
        <d-button @click="setColor('#FF6978')" :style="{'--color': '#FF6978'}"
                  :class="{active: currentColor === '#FF6978'}" class="button undo color">
          <div class="circle"></div>
        </d-button>
        <d-button @click="setColor('#49DBB9')" :style="{'--color': '#49DBB9'}"
                  :class="{active: currentColor === '#49DBB9'}" class="button undo color">
          <div class="circle"></div>
        </d-button>

        <d-button @click="undo" class="button undo"><img src="../assets/icons/undo.svg"></d-button>
        <d-button @click="clear" class="button clear"><img src="../assets/icons/clear.svg"></d-button>
      </section>
    </section>
  </section>
</template>

<script>
// import "../lib/fabric"
import {fabric} from "fabric"
import "fabric-history"
import DButton from "@/components/DButton";

export default {
  name: "DCanvas",
  components: {DButton},
  props: {
    resolution: {
      type: Number,
      default: 250
    },
  },
  data() {
    return {
      ctx: null,
      canvas: null,
      currentColor: '#464686'
    }
  },
  methods: {
    setColor(color) {
      this.currentColor = color
      this.canvas.freeDrawingBrush.color = this.currentColor
    },
    undo() {
      this.canvas.undo();
    },
    clear() {
      if (confirm("zeker weten?"))
        this.canvas.clear()
    },
    toDataURL() {
      return this.canvas.toSVG()
    },
    isCanvasEmpty() {
      const context = this.$refs.canvas.getContext('2d');

      const pixelBuffer = new Uint32Array(
          context.getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height).data.buffer
      );

      return !pixelBuffer.some(color => color !== 0);
    },
    setData64(data) {
      console.log(this.canvas)
      fabric.loadSVGFromString(data, (results) => {
        this.canvas.add(...results)
        this.canvas.clearHistory()
      });
    },
  },
  mounted() {
    this.canvas = new fabric.Canvas(this.$refs.canvas)

    this.canvas.isDrawingMode = true
    this.canvas.freeDrawingBrush.width = 5
    this.canvas.freeDrawingBrush.color = this.currentColor

    this.canvas.setHeight(this.resolution)
    this.canvas.setWidth(this.resolution)

    // this.canvas.initialize();

    this.ctx = this.canvas.getContext('2d')
  }
}
</script>

<style scoped lang=scss>
.DCanvas {
  position: relative;

  display: flex;
  flex-direction: column;

  label {
    background: var(--achtergrond);
    font-size: 20px;
    padding: 0 0 2px 15px;
    box-sizing: border-box;
    color: var(--tekst-donker);
    font-weight: bold;
    text-align: start;
  }

  canvas {

    box-sizing: border-box;
    border-radius: 15px;
    width: 250px;
    height: 250px;
    background: var(--licht-achtergrond);
    position: relative;

  }

  & > .wrapper {
    width: 300px;
    display: flex;
    justify-content: space-between;

    &::after {
      content: '';
      border-radius: 15px;
      width: 250px;
      height: 250px;
      position: absolute;
      border: var(--tekst-donker) 3px solid;
      box-sizing: border-box;
      pointer-events: none;
    }
  }

}


.buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;

  .button {
    .circle {
      background: var(--color);
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }
}
</style>

<style lang="scss">
.buttons {
  .button {

    width: 40px;
    height: 40px;
    margin-bottom: 8px;

    button {
      background: var(--licht-achtergrond);
      //width: 70px;
      //height: 70px;
      //border: var(--tekst-donker) 3px solid;
      //background: var(--licht-achtergrond);
      outline: none;
      //border-radius: 5px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin-top: 5px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .background {
      bottom: -8px;
      background: var(--licht-achtergrond);
    }

    &.active {
      button, .background {
        background: var(--color);
      }

    }
  }

}

</style>