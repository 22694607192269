<template>
  <section class="wrapper">
    <button v-bind="props" class="DButton" v-on="$listeners">
      <slot/>
    </button>
    <div class="background">
    </div>
  </section>
</template>

<script>
export default {
  name: "DButton",
  computed: {
    props() {
      return {...this.$attrs, ...this.$props};
    },
  },
}
</script>

<style scoped lang=scss>
.wrapper {
  position: relative;
}

.background {
  box-sizing: border-box;
  content: '';
  position: absolute;
  //width:100%;
  //height:100%;
  top: 9px;
  left: 0px;
  right: 0px;
  bottom: -12px;
  border-radius: 500px;
  outline: 0;
  //z-index: -1;
  background: #40C1A3;
  border: var(--tekst-donker) 4px solid;

  transition: .1s;
}

:disabled ~ .background {
  background: #6F6F6F;

}

.DButton {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--tekst-donker);
  font-size: 40px;
  font-weight: bold;
  border-radius: 500px;
  outline: 0;
  border: var(--tekst-donker) 3px solid;
  box-sizing: border-box;
  padding: 10px 20px;


  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


  background: var(--letter-goed);
  cursor: pointer;
  transition: .1s;

  @media (pointer:fine) {
    &:hover:not(:disabled) {
      margin-top: 2px;
      margin-bottom: -2px;
    }
  }



  &:disabled {
    cursor: not-allowed;
    background: #ADADAD;

  }


}

.active > .DButton:not(:disabled), .DButton:active:not(:disabled) {
    margin-top: 5px;
    margin-bottom: -5px;
}



</style>