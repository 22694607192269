import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// import VueI18n from 'vue-i18n'
// import en from './locales/en'
// import nl from './locales/nl'


import 'fabric';
import 'fabric-history';


import VueSignalR from "./lib/signalr.js"
import i18n from './i18n'

// Vue.use(VueSignalR, 'https://dingo-api.azurewebsites.net/gamehub')
Vue.use(VueSignalR, 'https://999-demo-dingo-api.azurewebsites.net/gamehub')


// const messages = {
//   en,
//   nl
// }
//
// // VueI18n instance
//
//
// Vue.use(VueI18n, {
//   locale: 'nl',
//   messages
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
